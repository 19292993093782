// src/components/TextCopyArea.js

import React, { useEffect, useState } from 'react';

function TextCopyArea({ initialText }) {
    const [text, setText] = useState('');

    useEffect(() => {
        setText(initialText);
    }, [initialText]);
    const copyToClipboard = () => {
        navigator.clipboard.writeText(text).then(() => {
            alert('Tekst został skopiowany do schowka!');
        }).catch(err => {
            alert('Wystąpił błąd podczas kopiowania: ' + err);
        });
    };

    return (
        <div className="text-copy-area">
            <textarea
                value={text}
                placeholder="Generated XML will appear here."
                onChange={(e) => setText(e.target.value)}
                rows="10"
                cols="80"
                style={{ width: '100%', fontFamily: 'monospace' }}
            />
            <br />
            <button onClick={copyToClipboard}>Kopiuj</button>
        </div>
    );
}

export default TextCopyArea;
