import React from 'react';

const InputField = ({ name, value, onChange, placeholder, type }) => {
  return (
    <div>
      <label htmlFor={name}>{placeholder}</label>
      <input
        type={type}
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      />
    </div>
  );
};

export default InputField;
