import React, { useEffect, useState } from 'react';
import axios from 'axios';
import InputField from './InputField';
import ResultsTable from './ResultsTable';
import loadConfig from './Config';

function SocialandTax() {
  const [formData, setFormData] = useState({
    date_income: '',
    gross_EUR_emp: '',
    gross_EUR_usr: '',
  });
  const [results, setResults] = useState('');
  const [exchangeRate, setExchangeRate] = useState(null);
  const [config, setConfig] = useState(null);
  useEffect(() => {
    const fetchConfig = async () => {
      const configData = await loadConfig();
      setConfig(configData);
    };

    fetchConfig();
  }, []);
  // Funkcja do pobierania kursu Euro
  const fetchExchangeRate = async (date) => {
    try {
      const response = await axios.get(`${config.NBP_API_BASE_URL}${date}/?format=json`);
      return response.data.rates[0].mid;
    } catch (error) {
      console.error('Error fetching exchange rate:', error);
      return null;
    }
  };
  const clearTable = () => {
    setResults({});
  };
  // Funkcja do obsługi zmiany w polu formularza
  const handleChange = async (e) => {
    const { name, value } = e.target;
    const updatedFormData = { ...formData, [name]: value };
    setFormData(updatedFormData);
    clearTable();
    // Jeśli zmieniła się data, pobierz kurs Euro
    if (name === 'date_income') {
      const rate = await fetchExchangeRate(value);
      setExchangeRate(rate);
    }
  };

  // Funkcja do obsługi przesyłania formularza
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${config.API_BASE_URL}/api/paycheck`, {
        ...formData,
        exchangeRate
      });
      setResults(response.data);
    } catch (error) {
      console.error('Error submitting the form:', error);
    }
  };

  // Definicja pól formularza
  const paycheckFields = [
    { name: 'date_income', placeholder: 'Enter Date of income', type: 'date' },
    { name: 'gross_EUR_emp', placeholder: 'Enter gross amount from employer', type: 'text' },
    { name: 'gross_EUR_usr', placeholder: 'Enter gross payment for employee', type: 'text' }
  ];
  if (!config) {
    return <div>Loading configuration...</div>;
  }
  return (
    <div className="App">
      <header className="App-header">
        <h1>Monthly settlement</h1>

        <form onSubmit={handleSubmit}>
          <div className="paycheck-fields">
            {paycheckFields.map(field => (
              <InputField
                key={field.name}
                name={field.name}
                value={formData[field.name]}
                onChange={handleChange}
                placeholder={field.placeholder}
                type={field.type}
              />
            ))}
          </div>
          <button type="submit">Submit</button>
        </form>

        <div className="results">
          {exchangeRate && (
            <div>Exchange Rate (EUR): {exchangeRate}</div>
          )}

          <ResultsTable results={results} clearTable={clearTable} />
        </div>
      </header>
    </div>
  );
}

export default SocialandTax;
