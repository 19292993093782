import React, { useMemo } from 'react';
import { useTable, useSortBy } from 'react-table';

const ResultsTable = ({ results, clearTable }) => {
  // Przygotowanie danych do tabeli
  const data = useMemo(
    () => Object.entries(results).map(([label, value]) => ({ label, value })),
    [results]
  );

  // Definicja kolumn
  const columns = useMemo(
    () => [
      { Header: 'Label', accessor: 'label' },
      { Header: 'Value', accessor: 'value' }
    ],
    []
  );

  // Inicjalizacja tabeli z obsługą sortowania
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable(
    { columns, data },
    useSortBy // Dodaje funkcjonalność sortowania
  );

  return (
    <div className="results-table">
      <h2>Results</h2>
      {/*<button onClick={clearTable}>Clear Table</button>*/}
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())} key={column.id}>
                  {column.render('Header')}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? ' 🔽' // Ikona sortowania w dół
                        : ' 🔼' // Ikona sortowania w górę
                      : ''}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} key={row.id}>
                {row.cells.map(cell => (
                  <td {...cell.getCellProps()} key={cell.column.id}>
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ResultsTable;
