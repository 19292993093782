import React, { useState } from 'react';
import PPK from './components/PPK';
import SocialandTax from './components/SocialandTax';
import './App.css';

function App() {
  const [activeComponent, setActiveComponent] = useState('');

  const showPPK = () => setActiveComponent('PPK');
  const showPaycheck = () => setActiveComponent('SocialandTax');
  const hideComponents = () => setActiveComponent('');

  const appStyle = {
    background: 'linear-gradient(135deg, #576172, #2d3b47)',
    color: 'white',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  };

  const buttonContainerStyle = {
    display: 'flex',
    gap: '10px',
    marginTop: '20px',
  };

  const logoPlaceholderStyle = {
    width: '200px',
    height: '200px',
    marginBottom: '20px',
  };

  return (
    <div className="App" style={appStyle}>
      <img src={`${process.env.PUBLIC_URL}/logo512.png`} alt="Finmate Logo" style={logoPlaceholderStyle} onClick={hideComponents} />
      
      <div style={buttonContainerStyle}>
        <button onClick={showPPK}>Show PPK</button>
        {/* <button onClick={showTestPart}>Show TestPart</button> */}
        <button onClick={showPaycheck}>Show Paycheck</button>
      </div>

      {activeComponent === 'PPK' && <PPK />}
      {activeComponent === 'SocialandTax' && <SocialandTax />}
    </div>
  );
}

export default App;
